import React from "react";

export default function SectionConduct() {
  return (
    <section className="section section--grey section--full-width increase-padding">
      <div className="inner-container">
        <h2>
          Conduct a pain assessment<sup>2</sup>
        </h2>
        <p>
          When treating a patient for pain, appropriate pain assessment is vital
          as a first step for effective pain management. It will be important to
          discuss your patient’s level of pain and to what extent their pain is
          constant or intermittent (breakthrough pain).
        </p>
      </div>
    </section>
  );
}
