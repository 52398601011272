export const greenBackground = (backgroundRef, size, position) => {
  if (position === "left") {
    if (window.innerWidth < 700) {
      const section = backgroundRef.current.parentNode;
      const backgroundContainer = section.querySelector(".left");
      const rect = backgroundContainer.getBoundingClientRect();

      const sectionPadding = parseInt(
        window
          .getComputedStyle(section, null)
          .getPropertyValue("padding-top")
          .replace("px", "")
      );

      backgroundRef.current.style.height = `${rect.height + sectionPadding}px`;
      backgroundRef.current.style.width = `${rect.width / 2}px`;
    } else {
      backgroundRef.current.style.width = `${size.x + size.width / 2}px`;
    }
  } else {
    const parentElm = backgroundRef.current.parentNode;
    const parentPositions = parentElm.getBoundingClientRect();
    const parentWidth = parentElm.offsetWidth;
    backgroundRef.current.style.left = `${parentWidth / 2}px`;
    //backgroundRef.current.style.right = `0`;
    backgroundRef.current.style.width = `${
      window.innerWidth - parentPositions.x - parentElm.offsetWidth / 2
    }px`;
  }
};
