import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { accept } from "../../reducers/hcpSlice";
import { useSite } from "../../hooks";
import { Footer, ExternalLink } from "../";
import logo from "../../img/logo.svg";

export default function HCPPopup() {
  const dispatch = useDispatch();
  const { currentSite } = useSite();
  const location = useLocation();
  let navigate = useNavigate();

  const close = (navigateTo) => {
    if (currentSite === "root") {
      navigate(navigateTo, { replace: true });
      dispatch(accept());
    } else {
      dispatch(accept());

      if (location.pathname.startsWith(`/${navigateTo}`)) {
        navigate(location.pathname + location.hash, { replace: true });
      } else {
        navigate(navigateTo, { replace: true });
      }
    }

    document.body.classList.remove(`site-hcp`);
    document.body.classList.remove(`site-patient`);
    document.body.classList.remove(`site-root`);
    document.body.classList.add(`site-${navigateTo}`);
  };

  return (
    <>
      <div className="hcp-popup-header">
        <div className="logo">
          <img src={logo} alt="Bunov&reg;" />
        </div>
        <div className="inner-container">
          <svg
            viewBox="0 0 572 485"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="popup-circles hide-mobile"
          >
            <circle opacity="0.2" cx="161" cy="334" r="150.5" stroke="#FCFCFC" />
            <circle opacity="0.2" cx="142.5" cy="387.5" r="80" stroke="#FCFCFC" />
            <circle opacity="0.2" cx="286" r="285.5" stroke="#FCFCFC" />
          </svg>

          <h1>
            Welcome to Glenmark’s
            <br /> Bunov<sup>&reg;</sup> (buprenorphine)
            <br /> UK product website
          </h1>
          <div className="button-container">
            <button
              className="btn btn--primary enter-patient"
              onClick={() => close("patient")}
            >
              I am a UK patient who has been prescribed Bunov<sup>&reg;</sup>
            </button>
            <button className="btn btn--primary enter-hcp" onClick={() => close("hcp")}>
              I am a UK healthcare professional
            </button>
          </div>
          <p className="disclaimer">
            By clicking on this link, you are confirming you are a UK Healthcare
            Professional.
          </p>
          <div className="link-container">
            <p className="disclaimer">
              If neither of the two options apply to you, but you would like more
              information, please refer to the following:
            </p>
            <ul>
              <li>
                <ExternalLink
                  link="https://www.medicines.org.uk/emc/search?q=bunov"
                  title="Summary of Product Characteristics"
                  className="welcome-spc"
                />
              </li>
              <li>
                <ExternalLink
                  link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
                  title="Patient Information Leaflet"
                  className="welcome-pil"
                />
              </li>
              <li>
                <a href="/par/" target="_blank" className="welcome-par">
                  Public Assessment Report
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="section section--grey section--full-width hcp-popup">
        <div className="inner-container">
          <h2>Reporting side effects</h2>
          <p>
            If you get any side effects, talk to your doctor, pharmacist or nurse. This
            includes any possible side effects not listed in the package leaflet. You can
            also report side effects directly via the Yellow Card Scheme at{" "}
            <ExternalLink
              link="https://yellowcard.mhra.gov.uk/"
              title="https://yellowcard.mhra.gov.uk/"
            />
            . By reporting side effects, you can help provide more information on the
            safety of this medicine.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}
