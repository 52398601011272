import React from "react";
import { Image } from "../../../components";
import RedBoxJPG from "../../../img/red-box.png";
import RedBoxWEBP from "../../../img/red-box.webp";
import GreenBoxJPG from "../../../img/green-box.png";
import GreenBoxWEBP from "../../../img/green-box.webp";
import BlueBoxJPG from "../../../img/blue-box.png";
import BlueBoxWEBP from "../../../img/blue-box.webp";

export default function SectionAvailableDoses() {
  return (
    <section className="section section--white section--full-width">
      <div className="inner-container">
        <h2 id="available-doses">
          Available doses of Bunov<sup>&reg;</sup>
        </h2>
        <p>
          Available in multiple strengths so you can choose the most appropriate
          dose for your patients:<sup>3</sup>
        </p>
        <div className="product-list">
          <div className="prod-wrap">
            <Image
              webp={BlueBoxWEBP}
              src={BlueBoxJPG}
              alt="Box of Bunov (buprenorphine) transdermal patches at the five micrograms per hour dose."
          />
            <p className="bold">
            Each Bunov<sup>&reg;</sup> 5 µg/h transdermal patch releases 5mg buprenorphine per hour over 7 days<sup>3</sup>
            </p>
          </div>
          <div className="prod-wrap">
            <Image
                webp={RedBoxWEBP}
                src={RedBoxJPG}
                alt="Box of Bunov (buprenorphine) transdermal patches at the ten micrograms per hour dose."
            />
            <p className={"bold"}>
              Each Bunov<sup>&reg;</sup> 10 µg/h transdermal patch releases 10mg buprenorphine per hour over 7 days<sup>3</sup>
            </p>
          </div>
          <div className="prod-wrap">
            <Image
                webp={GreenBoxWEBP}
                src={GreenBoxJPG}
                alt="Box of Bunov (buprenorphine) transdermal patches at the twenty micrograms per hour dose."
            />
            <p className={"bold"}>
              Each Bunov<sup>&reg;</sup> 20 µg/h transdermal patch releases 20mg buprenorphine per hour over 7 days<sup>3</sup>

            </p>
          </div>



        </div>


        <p className="bold">
          Each patch of Bunov<sup>&reg;</sup> ensures 7 days of consistent
          buprenorphine delivery for your patients<sup>3,4</sup>
        </p>
      </div>
    </section>
  );
}
