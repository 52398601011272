import React from "react";

export default function SectionTreatmentPain() {
  return (
    <section className="section section--steps section--grey section--full-width">
      <div className="inner-container">
        <h2>
          When initiating any treatment for pain,
          <br />
          consider the following <span>S T E P S</span>:<sup>4,5</sup>
        </h2>
        <div className="step-block-container">
          <div className="step-block">
            <div className="letter">
              <div className="letter-inner">S</div>
            </div>
            <p>
              Is it <strong>S</strong>afe for
              <br /> the patient?
            </p>
          </div>
          <div className="step-block">
            <div className="letter">
              <div className="letter-inner">T</div>
            </div>
            <p>
              Can they <strong>T</strong>olerate
              <br /> this medication and
              <br /> any side effects?
            </p>
          </div>
          <div className="step-block">
            <div className="letter">
              <div className="letter-inner">E</div>
            </div>
            <p>
              Is the medication
              <br />
              <strong>E</strong>ffective?
            </p>
          </div>
          <div className="step-block">
            <div className="letter">
              <div className="letter-inner">P</div>
            </div>
            <p>
              Are they on the best
              <br /> <strong>P</strong>riced treatment?
            </p>
          </div>
          <div className="step-block">
            <div className="letter">
              <div className="letter-inner">S</div>
            </div>
            <p>
              Is the dosing
              <br /> regimen as <strong>S</strong>imple
              <br /> as possible?
            </p>
          </div>
        </div>
        <p className="bold">
          When prescribing buprenorphine patches,{" "}
          <span>brand name prescribing</span> is recommended to <br />
          reduce the risk of confusion and error in dispensing and
          administration<sup>6</sup>
        </p>
      </div>
    </section>
  );
}
