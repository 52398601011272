import React, { useEffect } from "react";
import GraphJPG from "../../../img/graph.svg";

export default function SectionActivelyManage() {
  useEffect(() => {
    positionGreenBackgroundMobile();

    window.addEventListener("resize", () => {
      positionGreenBackgroundMobile();
    });
  }, []);

  const positionGreenBackgroundMobile = () => {
    if (window.innerWidth <= 599) {
      const bgElm = document.querySelector(".green-overlap");
      const parentElm = bgElm.parentNode;
      const parentWidth = parentElm.offsetWidth;
      bgElm.style.left = `0`;
      bgElm.style.width = `${parentWidth}px`;

      bgElm.style.height = `551px`;
    }
  };

  return (
    <section className="section section--grey section--half-half section--half-half-green-overlap section-active-manage">
      <div className="green-overlap"></div>
      <div className="inner-container">
        <div className="left">
          <img
            src={GraphJPG}
            alt="Graph showing dose-titration of Bunov (buprenorphine) to a higher dose after day 3."
          />
          <p>
            Bunov<sup>&reg;</sup> is a weekly pain patch, and should be administered every 7th day. You
            should <strong>always</strong> start your patient on the lowest dose
            of Bunov
            <sup>&reg;</sup> (5 µg/h). Consideration should be given to the
            previous opioid history of the patient as well as to the current
            general condition and medical status of the patient. Wait at least
            72 hours before increasing the strength.<sup>1</sup>
          </p>
          <p>
            During initiation of treatment with Bunov<sup>&reg;</sup>,
            short-acting supplemental analgesics may be required as needed until
            analgesic efficacy with Bunov<sup>&reg;</sup> is attained.
            <sup>1</sup>
          </p>
          <p>
            Communication with your patient will be critical during this time,
            to ensure they are receiving suitable pain relief. Evaluation of the
            analgesic effect of Bunov<sup>&reg;</sup> should not be made before
            the patch has been on for at least 72 hours, when the maximum effect
            of a given dose is established.<sup>1</sup>
          </p>
        </div>
        <div className="right">
          <h2>
            Actively manage Bunov<sup>&reg;</sup> dosing with your patients
            <sup>1</sup>
          </h2>
          <p>
            It is important to remember that, as with all treatments, not all
            patients will respond in the same way to Bunov®. Levels of pain
            tolerance and opioid sensitivity will differ. Such variations mean
            assumptions should not be made prior to treatment regarding final
            dosing.
          </p>
          <p className="bold">
            Start all patients on the same dose (5 µg/h) and monitor their
            treatment until adequate pain relief is achieved.
          </p>
          <p>
            While wearing the patch, patients should be advised to avoid
            exposing the application site to external heat sources, such as
            heating pads, electric blankets, heat lamps, sauna, hot tubs, and
            heated water beds, etc., as an increase in absorption of
            buprenorphine may occur. When treating febrile patients, one should
            be aware that fever may also increase absorption resulting in
            increased plasma concentrations of buprenorphine and thereby
            increased risk of opioid reactions.
          </p>
          <p>
            To increase the dose, a larger patch should replace the patch that
            is currently being worn, or a combination of patches should be
            applied in different places to achieve the desired dose. It is
            recommended that no more than two patches are applied at the same
            time, up to a maximum total dose of <br />
            40 µg/h buprenorphine. A new patch should not be applied to the same
            skin site for the subsequent 3-4 weeks. Patients should be carefully
            and regularly monitored to assess the optimum dose and duration of
            treatment.
          </p>
        </div>
      </div>
    </section>
  );
}
