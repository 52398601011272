import { createSlice } from "@reduxjs/toolkit";

export const hcpSlice = createSlice({
  name: "hcp",
  initialState: {
    value: false,
  },
  reducers: {
    accept: (state) => {
      state.value = true;
    },
  },
});

export const { accept } = hcpSlice.actions;

export default hcpSlice.reducer;
