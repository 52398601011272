import React from "react";
import {
  Heading,
  Resource,
  References,
  ExternalLink,
  PatientSmallHeading,
} from "../../../components";
import { downloads, links } from "./resource-data";

export default function Resources() {
  return (
    <main>
      <PatientSmallHeading
        tagline="Resources"
        title="Don’t let your chronic pain define you"
        copy="<p>We support your pain management.</p><p>Use these resources to help you manage your pain.</p><p>Bunov® transdermal patches are not for long-term use.<sup>1</sup></p>"
      />

      <section className="section section--beige section--resources">
        <div className="resource-container">
          <h2>Our Downloads</h2>
          {downloads.map((download, idx) => {
            return <Resource key={idx} data={download} type="download" />;
          })}
        </div>
      </section>

      <section className="section section--white section--resources">
        <div className="resource-container">
          <h2>External Links</h2>
          {links.map((link, idx) => {
            return <Resource key={idx} data={link} type="link" />;
          })}
        </div>
      </section>
      <References showDivider={true}>
        <ol>
          <li>
            Bunov Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
            />
          </li>
        </ol>
      </References>
    </main>
  );
}
