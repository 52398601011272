import React, { useEffect, useRef } from "react";
import { AssessmentReportPDF } from "../resources";
import { Footer } from "../components";
import * as pdfjs from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function PAR() {
  const canvasRef = useRef();
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const parentRef = useRef(); 


  function handlePages(page, pdf) {
    var viewport = page.getViewport({ scale: 1.2 });
    const canvas = document.createElement("canvas");

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    page.render(renderContext);

    parentRef.current.appendChild(canvas);

    var currPage = page._pageIndex + 1;

    if (pdf !== null && currPage < pdf.numPages) {
      pdf.getPage(currPage + 1).then(function (p) {
        handlePages(p, pdf);
      });
    }
  }

  useEffect(() => {
    const loadingTask = pdfjs.getDocument(AssessmentReportPDF);
    loadingTask.promise.then(
      (loadedPdf) => {
        loadedPdf.getPage(1).then(function (page) {
          handlePages(page, loadedPdf);
        });
      },
      function (reason) {
        console.error(reason);
      }
    );
  });

  return (
    <>
      <main className="single-par">
        <section className="section section--grey section--full-width section--report">
          <div className="container">
            <div className="pdf-report" ref={parentRef}>
              <canvas ref={canvasRef}></canvas>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
