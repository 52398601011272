import React, { useEffect } from "react";
import {
  InitiatingHero,
  CTA,
  References,
  ExternalLink,
} from "../../../components";
import SectionConduct from "./section-conduct";
import SectionDefine from "./section-define-pain-management";
import SectionTreatmentPain from "./section-treatment-pain";
import SectionReview from "./section-review";
import SectionMakeSure from "./section-make-sure";
import SectionActivelyManage from "./section-actively-manage";
import SectionCarefullyConsider from "./section-carefully-consider";
import SectionSideEffectsPlan from "./section-side-effects-plan";

export default function InitiatingBunov() {
  useEffect(() => {
    document.title = "Bunov® - Initiating Bunov";
  }, []);

  return (
    <main>
      <InitiatingHero />
      <SectionConduct />
      <SectionDefine />
      <SectionTreatmentPain />
      <CTA
        title="Resources"
        heading="Still looking for more information on Bunov®?"
        copy="Find helpful links covering alternative methods of pain management to share<br />with your patients on our resources page"
        link="/hcp/resources/"
        linkCopy="Explore resources"
        className="initiating-explore with-circles"
      />
      <SectionMakeSure />
      <SectionActivelyManage />
      <SectionCarefullyConsider />
      <SectionSideEffectsPlan />
      <SectionReview />
      <References>
        <ol>
          <li>
            Bunov Summary of Product Characteristics (SmPC). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
              title="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
            />
          </li>
          <li>
            Faculty of Pain Medicine. Patient assessment. Available at:{" "}
            <ExternalLink
              link="http://www.fpm.ac.uk/opioids-aware-structured-approach-opioid-prescribing/patient-assessment"
              title="http://www.fpm.ac.uk/opioids-aware-structured-approach-opioid-prescribing/patient-assessment"
            />
          </li>
          <li>
            Faculty of Pain Medicine. Checklist for prescribers. Available at:{" "}
            <ExternalLink
              link="http://www.fpm.ac.uk/opioids-aware-structured-approach-opioid-prescribing/checklist-prescribers"
              title="http://www.fpm.ac.uk/opioids-aware-structured-approach-opioid-prescribing/checklist-prescribers"
            />
          </li>
          <li>
            NHS Sunderland CCG. Opioid prescribing: resource pack. Available at:{" "}
            <ExternalLink
              link="https://www.sunderlandccg.nhs.uk/wp-content/uploads/2019/07/20190327-SCCG-and-STCCG-Opioid-resource-pack-Final-Approved-1.pdf"
              title="https://www.sunderlandccg.nhs.uk/wp-content/uploads/2019/07/20190327-SCCG-and-STCCG-Opioid-resource-pack-Final-Approved-1.pdf"
            />
          </li>
          <li>Waller DG. Clin Med (Lond) 2005;5:26–28.</li>
          <li>
            NHS Specialist Pharmacy Service. Example medicines to prescribe by
            brand name in primary care. Available at:{" "}
            <ExternalLink
              link="https://www.sps.nhs.uk/articles/example-medicines-to-prescribe-by-brand-name-in-primary-care/"
              title="https://www.sps.nhs.uk/articles/example-medicines-to-prescribe-by-brand-name-in-primary-care/"
            />
          </li>
          <li>
            NHS Specialist Pharmacy Service. Buprenorphine patches – how can you
            minimise the risk of medication errors? Available at:{" "}
            <ExternalLink
              link="https://www.sps.nhs.uk/wp-content/uploads/2019/04/SPS-QA-Buprenorphine-patches-Feb-2019-Final.pdf"
              title="https://www.sps.nhs.uk/wp-content/uploads/2019/04/SPS-QA-Buprenorphine-patches-Feb-2019-Final.pdf"
            />
          </li>
        </ol>
      </References>
    </main>
  );
}
