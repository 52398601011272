import React, { useEffect } from "react";
import { Image, ExternalLink } from "../../";
import HeroJPG from "../../../img/initiating-hero.jpg";
import HeroWEBP from "../../../img/initiating-hero.webp";

export default function InitiatingHero() {
  useEffect(() => {
    positionImage();

    window.addEventListener("resize", () => {
      positionImage();
    });
  }, []);

  const positionImage = () => {
    const heroElm = document.querySelector(".hero--initiating");

    if (heroElm) {
      const paddingBottom = parseInt(
        window.getComputedStyle(heroElm, null).getPropertyValue("padding-bottom").replace("px")
      );
      const imageContainer = heroElm.querySelector(".right");
      const imageElm = imageContainer.querySelector(".hero-image");

      const rect = imageContainer.getBoundingClientRect();
      if (window.innerWidth <= 700) {
        imageElm.style.height = `294px`;
      } else {
        imageElm.style.height = `${imageContainer.offsetHeight + paddingBottom + 35}px`;
      }

      imageElm.style.width = `${window.innerWidth - rect.x}px`;
    }
  };

  return (
    <div className="hero hero--initiating">
      <div className="inner-container">
        <svg viewBox="0 0 735 580" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.2"
            d="M272.606 -0.5C248.015 49.5508 196.531 84 137 84C77.4695 84 25.9849 49.5508 1.39404 -0.5"
            stroke="#FCFCFC"
          />
          <path
            opacity="0.2"
            d="M245.291 579.5C242.509 571.344 241 562.598 241 553.5C241 509.041 277.041 473 321.5 473C365.959 473 402 509.041 402 553.5C402 562.598 400.491 571.344 397.709 579.5"
            stroke="#FCFCFC"
          />
          <path
            opacity="0.2"
            d="M165.826 -0.5C181.962 142.151 303.036 253 450 253C596.964 253 718.038 142.151 734.174 -0.5"
            stroke="#FCFCFC"
          />
        </svg>

        <div className="left">
          <h1>
            Guidance for initiating your patients on Bunov<sup>&reg;</sup> (buprenorphine)
          </h1>
          <p>
            This page contains important information to consider when initiating your patients on
            Bunov<sup>&reg;</sup>. Please read the full{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
              title="Summary of Product Characteristics"
            />{" "}
            before prescribing Bunov<sup>&reg;</sup>.{" "}
          </p>
          <p>
            Bunov<sup>&reg;</sup> is indicated for treatment of adults with non-malignant pain of
            moderate intensity when an opioid is necessary for obtaining adequate analgesia. Bunov
            <sup>&reg;</sup> is not suitable for the treatment of acute pain.<sup>1</sup>
          </p>
        </div>
        <div className="right">
          <Image
            webp={HeroWEBP}
            src={HeroJPG}
            className="hero-image"
            alt="A doctor is talking to their patient in-clinic."
          />
        </div>
      </div>
    </div>
  );
}
