import React from "react";
import { Image } from "../../../components";
import BTNDownload from "../../../img/hcp-download-icon.png";
import Couple2JPG from "../../../img/couple-2.jpg";
import Couple2WEBP from "../../../img/couple-2.webp";
import { downloads } from "../resources/resource-data.js";

export default function SectionMakeSure() {
  return (
    <section className="section section--white section--half-half increase-padding">
      <div className="inner-container">
        <div className="left">
          <h2>Make sure your patient understands how to use Bunov® patches</h2>
          <p>
            Discuss the frequency of patch removal and renewal with your patient
            and those close to them. Confirm that they understand that the
            previous Bunov® patch must be removed prior to the next application
            and that the same application site cannot be used without a 3–4 week
            break.<sup>1</sup> Ensure the prescription also clearly states the
            frequency of application to avoid any confusion.<sup>7</sup>
          </p>
          <p>
            Share our Bunov® transdermal patch placement chart with your patients to allow
            them to track when and where they are applying their patches each
            week.
          </p>

          <a
            target="_blank"
            rel="noreferrer"
            href={downloads[1].link}
            className="btn btn--primary btn--icon patch-location"
          >
            Patch placement chart{" "}
            <img src={BTNDownload} alt="" style={{ width: 11 }} />
          </a>
        </div>
        <div className="right single-image">
          <Image
            webp={Couple2WEBP}
            src={Couple2JPG}
            alt="A middle-aged couple are laughing and enjoying their spare time."
          />
        </div>
      </div>
    </section>
  );
}
