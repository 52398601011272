import React, { useEffect } from "react";
import { Heading, Resource, CTA } from "../../../components";
import { downloads, links } from "./resource-data";

export default function Resources() {
  useEffect(() => {
    document.title = "Bunov® - Resources";
  }, []);

  return (
    <main>
      <Heading title="Resources">
        <p className="bold">
          We support you and your patients in managing their moderate intensity,
          non-malignant pain.{" "}
        </p>
        <p>
          Utilise these resources for patients prescribed Bunov® (buprenorphine)
          to help them manage their pain.
        </p>
      </Heading>

      <section className="section section--grey section--resources">
        <div className="resource-container">
          <h2>Our Downloads</h2>
          {downloads.map((download, idx) => {
            return <Resource key={idx} data={download} type="download" />;
          })}
        </div>
      </section>

      <section className="section section--white section--resources">
        <div className="resource-container">
          <h2>External Links</h2>
          {links.map((link, idx) => {
            return <Resource key={idx} data={link} type="link" />;
          })}
        </div>
      </section>

      <CTA
        title="Contact us"
        heading="Still have questions about prescribing Bunov®?"
        copy="Contact your local Glenmark Pharmaceuticals representative"
        link="mailto:Neurology.UK@glenmarkpharma.com?subject=Bunov&reg; - HCP website contact"
        linkCopy="Email a representative"
        className="with-circles"
      />
    </main>
  );
}
