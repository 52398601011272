import React, { useEffect } from "react";
import { Heading } from "../../../components";
import { AssessmentReportPDF } from "../../../resources";

export default function AssessmentReport() {
  useEffect(() => {
    document.title = "Bunov® - Assessment Report";
  }, []);

  return (
    <main>
      <Heading title="Public Assessment Report" />
      <section className="section section--grey section--full-width section--report">
        <div className="container">
          <iframe
            src={`${AssessmentReportPDF}#toolbar=0`}
            title="Public Assessment Report"
            frameBorder="0"
          ></iframe>
        </div>
      </section>
    </main>
  );
}
