import React from "react";

export default function SectionContraindications() {
  return (
    <section className="section section--contraindications section--white section--full-width">
      <div className="inner-container">
        <h2>
          Contraindications<sup>3</sup>
        </h2>
        <p>
          Bunov<sup>&reg;</sup> transdermal patches are contraindicated in:
        </p>
        <ul>
          <li>
            patients with hypersensitivity to the active substance or any of its
            constituents
          </li>
          <li>
            opioid-dependent patients and for narcotic withdrawal treatment
          </li>
          <li>
            conditions in which the respiratory centre and function are severely
            impaired (or may become so)
          </li>
          <li>
            patients who are receiving MAO inhibitors or have taken them within
            the last two weeks
          </li>
          <li>patients suffering from myasthenia gravis</li>

          <li>patients suffering from delirium tremens</li>
        </ul>
      </div>
    </section>
  );
}
