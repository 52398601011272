import React from "react";

export default function References(props) {
  const className = props.showDivider
    ? "section section--references section--white with-divider"
    : "section section--references section--white";

  return (
    <section className={className}>
      <div className="inner-container">
        <h6>References</h6>
        {props.children}
      </div>
    </section>
  );
}
