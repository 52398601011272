import React from "react";
import { ExternalLink } from "../../../components";
export default function SectionCarefullyConsider() {
  return (
    <section className="section section--white section--half-half">
      <div className="inner-container">
        <div className="left">
          <h2>
            Carefully consider dosing in patients converting from another opioid
            treatment
          </h2>
          <p>
            Bunov<sup>&reg;</sup> can be used as an alternative treatment to
            other opioids. Such patients should always be started on the lowest
            available dose (5 µg/h) and can take short-acting supplemental
            analgesics during titration, as required.<sup>1</sup>
          </p>
        </div>
        <div className="right">
          <div className="green-block">
            <svg viewBox="" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.2"
                d="M506.525 279C485.89 220.178 429.871 178 364 178C298.129 178 242.109 220.178 221.475 279"
                stroke="#FCFCFC"
              />
              <path
                opacity="0.2"
                d="M460.5 223.5C416.317 223.5 380.5 187.683 380.5 143.5C380.5 99.3172 416.317 63.5 460.5 63.5C493.344 63.5 521.567 83.293 533.889 111.604V175.396C521.567 203.707 493.344 223.5 460.5 223.5Z"
                stroke="#FCFCFC"
              />
              <path
                opacity="0.2"
                d="M238.844 -0.5C186.885 71.2932 102.397 118 7 118C4.66 118 2.32656 117.972 0 117.916"
                stroke="#FCFCFC"
              />
            </svg>

            <p className="bold">
              For more detailed guidance on opioid <br />
              prescribing from the Faculty of Pain Medicine
            </p>
            <ExternalLink
              link="http://www.fpm.ac.uk/opioids-aware/structured-approach-opioid-prescribing"
              title="Read the guidance"
              className="btn btn--primary faculty-of-pain"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
