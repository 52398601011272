import DownloadIcon1 from "../../../img/patient-download-icon-1.svg";
import DownloadIcon2 from "../../../img/patient-download-icon-2.svg";
import DownloadIcon3 from "../../../img/patient-download-icon-3.svg";
import LinkIcon1 from "../../../img/patient-link-icon-1.svg";
import LinkIcon2 from "../../../img/patient-link-icon-2.svg";
import LinkIcon3 from "../../../img/patient-link-icon-3.svg";
import LinkIcon4 from "../../../img/patient-link-icon-4.svg";
import LinkIcon5 from "../../../img/patient-link-icon-5.svg";

export const downloads = [
  {
    title: "Step-by-step application guide",
    description: "Use this helpful guide to ensure the correct application of your Bunov® patch.",
    icon: <DownloadIcon1 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/bunov/Bunov%2BHCP%2Bwebsite%2Bdownload%2BStep%2Bby%2BStep%2B6.10.23_V1.0.pdf",
    trigger: "download-one",
  },
  {
    title: "Patch location tracker",
    description:
      "Your patch cannot be re-applied to the same site for 3–4 weeks. Use this helpful tracker to ensure you are applying the patches in the right place and correctly rotating patch locations from week to week.",
    icon: <DownloadIcon2 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/bunov/Bunov+patient+resource-+Patch+application+tracker+PDF.pdf",
    trigger: "download-two",
  },
  {
    title: "Side effect information",
    description:
      "Worried about your Bunov® side effects? Download this easy-to-read summary to find out more about known side effects of Bunov®, and how to report your side effects.",
    icon: <DownloadIcon3 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/bunov/Bunov%2BHCP%2Bwebsite%2Bdownload%2BSide%2Beffects%2B6.10.23_V1.0.pdf",
    trigger: "download-three",
  },
];

export const links = [
  {
    title: "Physiotherapy",
    description: "Find physiotherapy services in your area",
    icon: <LinkIcon1 />,
    link: "https://www.nhs.uk/service-search/other-services/Physiotherapy/LocationSearch/725",
    trigger: "link-3",
  },
  {
    title: "Meditation",
    description:
      "Try this 20-minute guided meditation for pain management.<br/>Please note, this is a private service that requires payment.",
    icon: <LinkIcon2 />,
    link: "https://www.meditainment.com/pain-management-meditation",
    trigger: "link-4",
  },
  {
    title: "Pathway through Pain",
    description:
      "The Pathway through Pain course is NHS-commissioned and delivers significant results for those seeking better self-management of chronic musculoskeletal pain.<br />Please note, this is a private service that requires payment.",
    icon: <LinkIcon3 />,
    link: "https://www.pathwaythroughpain.com",
    trigger: "link-5",
  },
  {
    title: "Flexibility exercises",
    description: "These exercises may help you manage your chronic pain",
    icon: <LinkIcon4 />,
    link: "https://www.nhs.uk/live-well/exercise/strength-and-flexibility-exercises/flexibility-exercises",
    trigger: "link-6",
  },
  {
    title: "Sitting exercises",
    description: "These exercises may help you manage your chronic pain",
    icon: <LinkIcon5 />,
    link: "https://www.nhs.uk/live-well/exercise/strength-and-flexibility-exercises/sitting-exercises",
    trigger: "link-7",
  },
];
