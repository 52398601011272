import React from "react";
import { useEffect } from "react";
import { downloads as HCPDownloads } from "./hcp/resources/resource-data";

export default function Redirect() {
  useEffect(() => {
    const path = new URL(window.location).pathname;
    const pieces = path.split("/");

    var filtered = pieces.filter(function (el) {
      return el !== "";
    });

    const site = filtered[1];

    if (site === "hcp") {
      const resource = parseInt(filtered[2]);

      if (HCPDownloads[resource]) {
        setTimeout(() => {
          window.location = HCPDownloads[resource].link;
        }, 1000);
      } else {
        window.location = "/";
      }
    } else {
      window.location = "/";
    }

    return;
  }, []);

  return <p></p>;
}
