import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../App";
import { ExternalLink } from "../../";
import logo from "../../../img/logo.svg";

export default function Header() {
  const location = useContext(AppContext);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    positionActiveLine();
  }, [location]);

  useEffect(() => {
    if (menuOpened) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [menuOpened]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      positionActiveLine();
    });
  }, []);

  const positionActiveLine = () => {
    const activeLink = document.querySelector("nav ul li a.active");
    const indicatorBall = document.querySelector(".indicator__ball");
    const indicatorLine = document.querySelector(".indicator__line");

    if (activeLink === null) {
      indicatorBall.classList.add("indicator__ball--hide");
      indicatorLine.classList.add("indicator__line--hide");
      return;
    } else {
      const { left } = activeLink.getBoundingClientRect();
      const linkWidth = activeLink.offsetWidth;
      const browserWidth = document.documentElement.clientWidth;

      indicatorBall.style.left = `${left + linkWidth / 2}px`;
      indicatorLine.style.width = `${browserWidth - (left + linkWidth / 2)}px`;

      indicatorBall.classList.remove("indicator__ball--hide");
      indicatorLine.classList.remove("indicator__line--hide");
    }
  };

  return (
    <>
      <div className="sticky-head">
        <div className="header__notice">
          <div className="container">
            <p>
              this website is only for uk patients that have been prescribed
              bunov
              <sup>&reg;</sup>
              (buprenorphine) &nbsp;&nbsp;| <a href="/">I am not a patient</a>
            </p>
          </div>
        </div>
        <div className="header__links">
          <div className="container">
            <ul>
              <li>
                <a href="#ae">
                  <span>Side effect reporting</span>
                </a>
              </li>

              <li>
                <ExternalLink link="https://www.medicines.org.uk/emc/files/pil.9797.pdf">
                  <span>Patient information leaflet</span>
                </ExternalLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header>
        <div className="header__main">
          <div className="container">
            <div className="header__main__logo">
              <NavLink to="/patient/">
                <img src={logo} alt="Bunov&reg;" />
              </NavLink>
            </div>
            <div
              className={menuOpened ? "burger-menu open" : "burger-menu"}
              onClick={(e) => setMenuOpened(!menuOpened)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav>
              <ul>
                <li>
                  <NavLink
                    to="/patient/how-to-apply-bunov"
                    data-path="/patient/how-to-apply-bunov"
                    onClick={() => setMenuOpened(false)}
                  >
                    How to apply Bunov<sup>&reg;</sup>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/patient/why-have-i-been-prescribed-bunov"
                    data-path="/patient/why-have-i-been-prescribed-bunov"
                    onClick={() => setMenuOpened(false)}
                  >
                    Why have I been prescribed Bunov<sup>&reg;</sup>?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/patient/side-effects"
                    data-path="/patient/side-effects"
                    onClick={() => setMenuOpened(false)}
                  >
                    Side effects
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/patient/useful-resources"
                    data-path="/patient/useful-resources"
                    onClick={() => setMenuOpened(false)}
                  >
                    Useful resources
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="indicator">
          <div className="indicator__ball"></div>
          <div className="indicator__line"></div>
        </div>
      </header>
      <div className="body-opacity"></div>
    </>
  );
}
