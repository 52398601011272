import React, { useEffect, useRef } from "react";
import { Image } from "../../../components";
import ManFieldJPG from "../../../img/man-field.jpg";
import GreenCircleJPG from "../../../img/bg-circles.png";
import GreenCircleWEBP from "../../../img/bg-circles.webp";
import useSize from "../../../hooks/useSize";
import { greenBackground } from "../../../utilities/greenBackground";

export default function SectionDefine() {
  const imageRef = useRef();
  const backgroundRef = useRef();
  const size = useSize(imageRef);

  useEffect(() => {
    if (typeof size.width !== "undefined")
      greenBackground(backgroundRef, size, "right");
  }, [size]);

  return (
    <section className="section section--white section--half-half">
      <div className="inner-container">
        <div className="left">
          <h2>
            Define a pain management plan with your patient and set measurable
            goals<sup>3</sup>
          </h2>
          <p>
            By defining the patient’s level of pain, and setting functional and
            achievable goals (e.g., whether they are aiming for pain-free
            movement or want to be pain-free at rest), you can help manage your
            patient’s expectations of the treatment plan.{" "}
          </p>
          <p>
            It is important to ensure your patient understands that these
            opioid / analgesic patches are not a long-term solution for their pain. They should be
            using these as part of a broader pain plan, including
            non-medication treatments and self-management. Confirm that your
            patient understands that the aim of this treatment is to reduce
            their pain to a level that allows them to sufficiently engage in
            self-management.
          </p>
        </div>
        <div className="right align-end">
          <div className="green-background" ref={backgroundRef}>
            <Image
              webp={GreenCircleWEBP}
              src={GreenCircleJPG}
              style={{ position: "absolute", top: 0 }}
            />
          </div>
          <img
            src={ManFieldJPG}
            className="couple-image man"
            ref={imageRef}
            alt="An elderly gentleman is out in the countryside enjoying some fresh air."
          />
        </div>
      </div>
    </section>
  );
}
