import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Footer, HCPPopup, PatientHeader as Header } from "./components";
import { Home, Why, How, Resources, SideEffects, PAR } from "./pages/patient";

export default function Patient() {
  const HCPStatus = useSelector((state) => state.value);

  const load = () => {
    return !HCPStatus ? (
      <HCPPopup />
    ) : (
      <>
        <Header />
        <Routes>
          <Route
            path="/patient/why-have-i-been-prescribed-bunov"
            element={<Why />}
          ></Route>
          <Route path="/patient/how-to-apply-bunov" element={<How />}></Route>
          <Route
            path="/patient/useful-resources"
            element={<Resources />}
          ></Route>
          <Route path="/patient/side-effects" element={<SideEffects />}></Route>
          <Route
            path="/patient/public-assessment-report"
            element={<PAR />}
          ></Route>
          <Route path="/patient" element={<Home />}></Route>
        </Routes>
        <Footer />
      </>
    );
  };

  return load();
}
