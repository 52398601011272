import React from "react";
import { Image } from "../../../components";
import PackJPG from "../../../img/packshot.png";
import PackWEBP from "../../../img/packshot.webp";
import PlusJPG from "../../../img/plus-icon.png";
import PlusWEBP from "../../../img/plus-icon.webp";
import EqualsJPG from "../../../img/equals-icon.png";
import EqualsWEBP from "../../../img/equals-icon.webp";

export default function SectionPrescribe() {
  return (
    <section className="section section--prescribe section--grey section--half-half">
      <div className="inner-container">
        <div className="left">
          <div className="block">
            Patient’s current opioid therapy
            <div className="icon">
              <Image webp={PlusWEBP} src={PlusJPG} alt=" " />
            </div>
          </div>

          <div className="block">
            Patient’s current opioid dose
            <div className="icon">
              <Image webp={EqualsWEBP} src={EqualsJPG} alt=" " />
            </div>
          </div>

          <div className="block block--large">
            <span>
              Bunov<sup>&reg;</sup> 7-day patch dose
            </span>
            <Image
              webp={PackWEBP}
              src={PackJPG}
              alt="Three boxes of Bunov (buprenorphine) transdermal patches, one of each available dose."
            />
          </div>
        </div>
        <div className="right">
          <h2>
            Prescribe Bunov<sup>&reg;</sup> for easy-to-use relief of non-malignant pain of moderate
            intensity for your patients
          </h2>
          <p>
            Bunov<sup>&reg;</sup> is a potent opioid analgesic that acts as a partial agonist at the
            µ-opiate receptor, and as an antagonist at the κ- and δ-receptors. Bunov<sup>&reg;</sup>{" "}
            transdermal patches are bioequivalent to BuTrans<sup>&reg;</sup> (Napp Pharmaceuticals
            Ltd) transdermal patches.<sup>2</sup>
          </p>
          <p>
            The analgesic potency of buprenorphine is 25–50 times higher (on a weight-by-weight
            basis) than that of morphine. Buprenorphine has been widely used for two decades and has
            proved to be a strong analgesic.<sup>2</sup>{" "}
          </p>
          <p>
            Bunov<sup>&reg;</sup> transdermal patches are indicated in adults for the treatment of
            non-malignant pain of moderate intensity when an opioid is necessary for obtaining
            adequate analgesia.<sup>3</sup>
          </p>
          <p>
            Bunov<sup>&reg;</sup> is not suitable for the treatment of acute pain. <sup>3</sup>
          </p>
        </div>
      </div>
    </section>
  );
}
