import React, { useState, useEffect } from "react";
import { Heading, ExternalLink } from "../../../components";
import { Formik, Form, Field } from "formik";
import ErrorIcon from "../../../img/error-icon.svg";
import Tick from "../../../img/tick.svg";
import * as Yup from "yup";

const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Field is required"),
  email: Yup.string().email("Invalid email").required("Field is required"),
  address1: Yup.string().required("Field is required"),
  town: Yup.string().required("Field is required"),
  country: Yup.string().required("Field is required"),
  message: Yup.string().required("Field is required"),
});

export default function ContactUs() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    document.title = "Bunov® - Contact us";

    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcymdcfAAAAABLLLKH6IgQaohfHFLTomAYfdCqQ";
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute("6LcymdcfAAAAABLLLKH6IgQaohfHFLTomAYfdCqQ", {
          action: "contact",
        })
        .then((token) => {
          setToken(token);
        });
    });
  };

  // "http://api.bunov.kvadev.com/api/email",
  return (
    <main className="contact">
      <Heading title="Contact us" />
      <section className="section section--grey section--full-width section--contact">
        <div className="resource-container">
          {!isSubmitted ? (
            <div
              className="recaptcha"
              data-sitekey="6LcymdcfAAAAABLLLKH6IgQaohfHFLTomAYfdCqQ_"
              data-size="invisible"
            >
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  address1: "",
                  address2: "",
                  town: "",
                  country: "",
                  message: "",
                }}
                validationSchema={formSchema}
                onSubmit={async (values, { resetForm }) => {
                  setIsSubmitting(true);
                  values.token = token;
                  console.log(values);
                  const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(values),
                  };
                  await fetch(
                    "https://api.bunov.co.uk/api/email",
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then(async (data) => {
                      if ((await data.response) === "Success") {
                        setIsSubmitted(true);
                      }
                    });
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-top">
                      <div className="form-row">
                        <div className="field">
                          <label htmlFor="firstName">Full Name</label>
                          <Field
                            name="name"
                            className={
                              errors.name && touched.name ? "error" : ""
                            }
                          />
                          <img src={ErrorIcon} alt="" />
                          <div
                            className={
                              errors.name && touched.name
                                ? "error-message"
                                : "error-message hide"
                            }
                          >
                            {errors.name || "&nbsp;"}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="email">Email address</label>
                          <Field
                            name="email"
                            className={
                              errors.email && touched.email ? "error" : ""
                            }
                          />
                          <img src={ErrorIcon} alt="" />
                          <div
                            className={
                              errors.email && touched.email
                                ? "error-message"
                                : "error-message hide"
                            }
                          >
                            {errors.email || "&nbsp;"}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="field">
                          <label htmlFor="address1">Address line 1</label>
                          <Field
                            name="address1"
                            className={
                              errors.address1 && touched.address1 ? "error" : ""
                            }
                          />
                          <img src={ErrorIcon} alt="" />
                          <div
                            className={
                              errors.address1 && touched.address1
                                ? "error-message"
                                : "error-message hide"
                            }
                          >
                            {errors.address1 || "&nbsp;"}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="address2">
                            Address line 2 (optional)
                          </label>
                          <Field name="address2" />
                          <img src={ErrorIcon} alt="" />
                          <div
                            className={
                              errors.address2
                                ? "error-message"
                                : "error-message hide"
                            }
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="field">
                          <label htmlFor="town">Town / City</label>
                          <Field
                            name="town"
                            className={
                              errors.town && touched.town ? "error" : ""
                            }
                          />
                          <img src={ErrorIcon} alt="" />
                          <div
                            className={
                              errors.town && touched.town
                                ? "error-message"
                                : "error-message hide"
                            }
                          >
                            {errors.town || "&nbsp;"}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="country">Country / Region</label>
                          <Field
                            name="country"
                            className={
                              errors.country && touched.country ? "error" : ""
                            }
                          />
                          <img src={ErrorIcon} alt="" />
                          <div
                            className={
                              errors.country && touched.country
                                ? "error-message"
                                : "error-message hide"
                            }
                          >
                            {errors.country || "&nbsp;"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-bottom">
                      <div className="field">
                        <label htmlFor="message">Your enquiry / message</label>
                        <Field
                          name="message"
                          as="textarea"
                          className={
                            errors.message && touched.message ? "error" : ""
                          }
                        />
                      </div>
                      <p>
                        Your details will be processed in accordance with our
                        Privacy Policy available at{" "}
                        <ExternalLink
                          link="https://glenmarkpharma.co.uk/privacy-policy./"
                          title="glenmarkpharma.co.uk/privacy-policy."
                        />{" "}
                      </p>
                      <div className="submit-wrapper">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn--primary form-submission-attempt"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="thank-you">
              <div className="icon">
                <img src={Tick} alt="" />
              </div>
              <p className="bold">Your enquiry has been sent</p>
              <p>
                A Glenmark Pharmaceuticals representative will be in touch
                shortly.
              </p>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
