import DownloadIcon1 from "../../../img/download-icon-1.svg";
import DownloadIcon2 from "../../../img/download-icon-2.svg";
import DownloadIcon3 from "../../../img/download-icon-3.svg";
import LinkIcon1 from "../../../img/link-icon-1.svg";
import LinkIcon2 from "../../../img/link-icon-2.svg";
import LinkIcon3 from "../../../img/link-icon-3.svg";
import LinkIcon4 from "../../../img/link-icon-4.svg";
import LinkIcon5 from "../../../img/link-icon-5.svg";
import LinkIcon6 from "../../../img/link-icon-6.svg";
import LinkIcon7 from "../../../img/link-icon-7.svg";
import LinkIcon8 from "../../../img/link-icon-8.svg";

export const downloads = [
  {
    title: "Step-by-step application guide",
    description:
      "Share this simple one-page step-by-step guide with your patients to ensure the correct application of Bunov® patches. The simple diagrams will ensure your patients have a thorough understanding on where and how the patches should be applied.",
    icon: <DownloadIcon1 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/bunov/Bunov%2BHCP%2Bwebsite%2Bdownload%2BStep%2Bby%2BStep%2B6.10.23_V1.0.pdf",
    trigger: "download-one",
  },
  {
    title: "Patch location tracker",
    description:
      "Bunov® patches must not be re-applied to the same body site for 4 weeks (28 days).<br/> Recommend your patients track their patch applications and removals in this helpful table to ensure consistency in their treatment.",
    icon: <DownloadIcon2 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/bunov/Bunov+patient+resource-+Patch+application+tracker+PDF.pdf",
    trigger: "download-two",
  },
  {
    title: "Side effect information",
    description:
      "Patients can often worry about the side effects associated with new treatments. Share this easy-to-digest summary of the side effects associated with Bunov® with your patients who have been prescribed Bunov®.",
    icon: <DownloadIcon3 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/bunov/Bunov%2BHCP%2Bwebsite%2Bdownload%2BSide%2Beffects%2B6.10.23_V1.0.pdf",
    trigger: "download-three",
  },
];

export const links = [
  {
    title: "Checklist for prescribers",
    description:
      "Use this handy checklist, provided by the Faculty of Pain Medicine, when considering the use of Bunov® patches in your patients.",
    icon: <LinkIcon1 />,
    link: "http://www.fpm.ac.uk/opioids-aware-structured-approach-opioid-prescribing/checklist-prescribers",
    trigger: "link-1",
  },
  {
    title: "A structured approach to opioid prescribing",
    description:
      "The Faculty of Pain Medicine provide a range of resources to help you manage prescription of opioids in your patients. Utilise these webpages to guide your opioid-prescribing practices.",
    icon: <LinkIcon2 />,
    link: "http://www.fpm.ac.uk/opioids-aware/structured-approach-opioid-prescribing",
    trigger: "link-2",
  },
  {
    title: "BNF buprenorphine webpage",
    description:
      "The British National Forumulary (BNF) for the National Institute for Health and Care Excellent (NICE) provide a range of resources for buprenorphine, including important safety information and indications and dosing",
    icon: <LinkIcon2 />,
    link: "https://bnf.nice.org.uk/drugs/buprenorphine/",
    trigger: "link-8",
  },
  {
    title: "WHO analgesic ladder",
    description:
      "The WHO guidelines for management of pain in adult and adolescent cancer patients",
    icon: <LinkIcon8 />,
    link: "https://www.who.int/publications/i/item/9789241550390",
    trigger: "link-9",
  },
  {
    title: "Physiotherapy",
    description:
      "You may want to discuss the impact that physiotherapy can have for patients with chronic pain.<br /> Find physiotherapy services in your area on this website to share with your patients.",
    icon: <LinkIcon3 />,
    link: "https://www.nhs.uk/service-search/other-services/Physiotherapy/LocationSearch/725",
    trigger: "link-3",
  },
  {
    title: "Meditation",
    description:
      "Suggest your patients try this 20-minute guided meditation for pain management. <br />Please note, this is a private service and your patients will need to pay for access.",
    icon: <LinkIcon4 />,
    link: "https://www.meditainment.com/pain-management-meditation",
    trigger: "link-4",
  },
  {
    title: "Pathway through Pain",
    description:
      "The Pathway through Pain course is NHS-commissioned and delivers significant results for those seeking better self-management of chronic musculoskeletal pain. Suggest your patients have a look at this course, or discuss with them in-clinic how this may benefit them. <br />Please note, this is a private service and your patients will need to pay for access.",
    icon: <LinkIcon5 />,
    link: "https://www.pathwaythroughpain.com",
    trigger: "link-5",
  },
  {
    title: "Flexibility exercises",
    description: "Recommend these exercises to help your patients manage their pain.",
    icon: <LinkIcon6 />,
    link: "https://www.nhs.uk/live-well/exercise/strength-and-flexibility-exercises/flexibility-exercises",
    trigger: "link-6",
  },
  {
    title: "Sitting exercises",
    description: "Recommend these exercises to help your patients manage their pain.",
    icon: <LinkIcon7 />,
    link: "https://www.nhs.uk/live-well/exercise/strength-and-flexibility-exercises/sitting-exercises",
    trigger: "link-7",
  },
];
