import React from "react";
import GraphJPG from "../../../img/graph.png";

export default function SectionGraph() {
  return (
    <section className="section section--grey section--half-half left-middle">
      <div className="inner-container" style={{"gap": "3rem"}}>
        <div className="left">
          <img
            src={GraphJPG}
            className="product-graph"
            alt="Graph showing dose-titration of Bunov (buprenorphine) to a higher dose after day 3."
          />
        </div>
        <div className="right">
          <p>
            The lowest Bunov<sup>&reg;</sup>transdermal patch dose (5 µg/h) should always
            be used as the initial dose. This can then be titrated to a higher
            dose after 3 days, when the maximum effect of a given dose is
            established. Subsequent dose increases may then be titrated based on
            the need for supplemental moderate pain relief and the patient’s analgesic
            response to the patch over the first few days of treatment.
            <sup>3</sup>
          </p>
          <p>
            During the course of the low-dose initiation (i.e., the first 3
            days), short-acting supplemental analgesics may be required as
            needed until the full analgesic effect of Bunov<sup>&reg;</sup> is
            achieved.<sup>3</sup>
          </p>
          <p>
            In the absence of adequate pain control, the possibility of
            hyperalgesia, tolerance and progression of underlying disease should
            be considered. A Bunov<sup>&reg;</sup> dose reduction or
            discontinuation of Bunov<sup>&reg;</sup> treatment or treatment
            review may be indicated.<sup>3</sup>
          </p>

        </div>
      </div>
    </section>
  );
}
