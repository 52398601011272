import React from "react";
import { PatientSmallHeading } from "../../../components";
import { AssessmentReportPDF } from "../../../resources";

export default function Resources() {
  return (
    <main>
      <PatientSmallHeading tagline="PAR" title="Public Assessment Report" />
      <section className="section section--grey section--full-width section--report">
        <div className="container">
          <iframe
            src={`${AssessmentReportPDF}#toolbar=0`}
            title="Public Assessment Report"
            frameBorder="0"
          ></iframe>
        </div>
      </section>
    </main>
  );
}
