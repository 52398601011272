import React from "react";
import logo from "../../img/glenmark-logo.svg";
import { ExternalLink } from "../";
import { useSite, useJobCode, useDOP } from "../../hooks";
import { useSelector } from "react-redux";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const { currentSite } = useSite();
  const hcpAccepted = useSelector((state) => state.value);

  return (
    <footer>
      <div className="footer__top">
        <div className="container">
          <div className="footer__top__left">
            <div className="top">
              <ExternalLink link="https://glenmarkpharma.co.uk/" className="footer-logo">
                <img src={logo} className="footer-logo" alt="Glenmark" />
              </ExternalLink>

              <div className="address">
                <span>
                  Glenmark Pharmaceuticals Europe Ltd. Building 2, Croxley Park, Watford
                  WD18 8YA
                </span>
                {currentSite === "hcp" || !hcpAccepted ? (
                  <a
                    className="footer-contact"
                    href="mailto:Neurology.UK@glenmarkpharma.com?subject=Bunov&reg; - HCP website contact"
                  >
                    Contact us
                  </a>
                ) : (
                  <ExternalLink
                    link="https://glenmarkpharma.co.uk/contact-us/"
                    title="Contact us"
                    className="footer-contact"
                  />
                )}
              </div>
            </div>
            <div className="bottom">
              <ul>
                <li>
                  <ExternalLink
                    link="https://www.medicines.org.uk/emc/search?q=bunov"
                    title="United Kingdom: Summary of Product Characteristics"
                    className="footer-smpc"
                  />
                </li>
                <li>
                  <ExternalLink
                    link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
                    title="Patient information leaflet"
                    className="footer-pil"
                  />
                </li>
                <li>
                  <a href="/par/" target="_blank" className="footer-par">
                    Public Assessment Report
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div id="ae" className="footer__top__right">
            <div className="side-effects">
              {currentSite === "patient" || !hcpAccepted ? (
                <p style={{ fontSize: "1.4rem" }}>
                  Reporting side effects: If you get any side effects, talk to your
                  doctor, pharmacist or nurse.
                  <br />
                  <br /> This includes any possible side effects not listed in the package
                  leaflet. You can also report side effects directly via the Yellow Card
                  Scheme at{" "}
                  <ExternalLink
                    link="https://yellowcard.mhra.gov.uk/"
                    title="https://yellowcard.mhra.gov.uk/."
                    className="footer-yellow"
                  />{" "}
                  By reporting side effects, you can help provide more information on the
                  safety of this medicine.
                </p>
              ) : (
                <p>
                  Adverse events should be reported. Reporting forms and information can
                  be found at{" "}
                  <ExternalLink
                    link="https://yellowcard.mhra.gov.uk/"
                    title="https://yellowcard.mhra.gov.uk/"
                    className="footer-yellow"
                  />{" "}
                  or search for MHRA Yellow Card in the Google Play or Apple App Store.
                  Adverse events should also be reported to Glenmark Pharmaceuticals
                  Europe by emailing{" "}
                  <a href="mailto:medical_information@glenmarkpharma.com">
                    medical_information@glenmarkpharma.com
                  </a>{" "}
                  or call <span className="phone">0800 458 0383</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="footer__bottom__left">
            <span className="copy">&copy; Copyright {currentYear}</span>
            <div className="pipe"></div>
            <div>
              <span className="job-code">{useJobCode()}</span>
              <span className="dop">{useDOP()}</span>
            </div>
          </div>
          <div className="footer__bottom__right">
            <ul>
              <li>
                <ExternalLink
                  link="https://glenmarkpharma.com/privacy-portal"
                  title="Privacy Notice"
                  className="footer-privacy"
                />
              </li>
              <li>
                <ExternalLink
                  link="https://glenmarkpharma.co.uk/terms-of-use/"
                  title="Terms &amp; Conditions"
                  className="footer-terms"
                />
              </li>
              <li>
                <ExternalLink
                  link="https://glenmarkpharma.com/cookie-policy/"
                  title="Cookie Policy"
                  className="footer-cookie"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
