import { useState, useEffect, useLayoutEffect, useContext } from "react";
import { AppContext } from "../App";

export default function useJobCode() {
  const { location } = useContext(AppContext);
  const [popupVisible, setPopupVisible] = useState(false);
  const [jobCode, setJobCode] = useState("NP-UK-Bun-0004");

  useLayoutEffect(() => {
    const hcpPopup = document.querySelector(".hcp-popup-header");

    if (hcpPopup) {
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (popupVisible) {
      setJobCode("NP-UK-Bun-0023");
    } else {
      switch (location.pathname) {
        case "/hcp":
          setJobCode("PP-UK-Bun-0078");
          break;
        case "/hcp/":
          setJobCode("PP-UK-Bun-0078");
          break;
        case "/par/":
          setJobCode("NP-UK-Bun-0022");
          break;
        case "/hcp/initiating-bunov":
          setJobCode("PP-UK-Bun-0070");
          break;
        case "/hcp/resources":
          setJobCode("PP-UK-Bun-0069");
          break;
        case "/hcp/contact-us":
          setJobCode("PP-UK-Bun-0067");
          break;
        case "/hcp/pi":
          setJobCode("PP-UK-Bun-0068");
          break;
        case "/patient/":
        case "/patient":
          setJobCode("NP-UK-Bun-0026");
          break;
        case "/patient/how-to-apply-bunov":
          setJobCode("NP-UK-Bun-0027");
          break;
        case "/patient/side-effects":
          setJobCode("NP-UK-Bun-0028");
          break;
        case "/patient/useful-resources":
          setJobCode("NP-UK-Bun-0025");
          break;
        case "/patient/why-have-i-been-prescribed-bunov":
          setJobCode("NP-UK-Bun-0024");
          break;
        default:
          setJobCode("NP-UK-Bun-0014");
          break;
      }
    }
  }, [popupVisible, location.pathname]);

  return jobCode;
}
