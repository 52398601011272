import React from "react";
import {
  PatientLargeHeading as LargeHeading,
  CTA,
  References,
  ExternalLink,
} from "../../../components";
import { NavLink } from "react-router-dom";
import HomeHeader from "../../../img/home-header.jpg";
import MenField from "../../../img/2-men.jpg";
import Box1 from "../../../img/box-1-inner.png";
import Box2 from "../../../img/box-2-inner.png";
import Box3 from "../../../img/box-3-inner.png";
import Box4 from "../../../img/box-4-inner.png";
import Arrow1 from "../../../img/arrow-1.svg";
import Arrow2 from "../../../img/arrow-2.svg";
import Arrow3 from "../../../img/arrow-3.svg";

export default function PatientHome() {
  return (
    <main>
      <LargeHeading
        image={HomeHeader}
        alt="An elderly couple are browsing a tablet computer."
        tagline="Welcome"
        title="Say hello to Bunov<sup>&reg;</sup>"
        body="Bunov<sup>&reg;</sup> is a strong analgesic or pain-relieving patch used to treat/manage chronic pain. It has been prescribed for you by your doctor to relieve, long-lasting moderate pain that requires the use of a strong painkiller.<sup>1</sup> Find information on how to use Bunov<sup>&reg;</sup> and what to expect from treatment on this site. Please read the patient information leaflet before using Bunov<sup>&reg;</sup>.<br /><br />It is important to read the product package leaflet provided with your medicine. This information is not a substitute for the product package leaflet. The package leaflet contains additional important information on the use of the medicine."
      />
      <section className="section section--white direction">
        <div className="container">
          <div className="direction-box">
            <div className="top"></div>
            <div className="content">
              <h3>
                Where to apply Bunov<sup>&reg;</sup> patches
              </h3>
              <p>
                Use our helpful step-by-step guide to ensure the correct application of your Bunov
                <sup>&reg;</sup> patches.
              </p>
            </div>
            <div className="bottom"></div>
            <a href="#how">&nbsp;</a>
          </div>
          <div className="direction-box">
            <div className="top"></div>
            <div className="content">
              <h3>
                Bunov<sup>&reg;</sup> side effects
              </h3>
              <p>
                You may experience side effects as a result of using Bunov
                <sup>&reg;</sup> patches. Click here to find out more about possible side effects.
              </p>
            </div>
            <div className="bottom"></div>
            <a href="#side-effects">&nbsp;</a>
          </div>
          <div className="direction-box">
            <div className="top"></div>
            <div className="content">
              <h3>Speaking to your doctor</h3>
              <p>
                It is really important to keep your healthcare team up-to-date with how your Bunov
                <sup>&reg;</sup> treatment is going.
              </p>
            </div>
            <div className="bottom"></div>
            <a href="#doctor">&nbsp;</a>
          </div>
        </div>
      </section>
      <section className="section section--beige section--half-half pointer pointer--top pointer--right-50">
        <div className="container mobile-reverse">
          <div className="left">
            <h2>
              Considerations before
              <br /> using Bunov<sup>&reg;</sup>
            </h2>
            <p className="bold">
              Do not use Bunov<sup>&reg;</sup> if:<sup>1</sup>
            </p>
            <ul>
              <li>
                you are allergic to buprenorphine or any of the other ingredients of this medicine
              </li>
              <li>you have breathing problems</li>
              <li>you are addicted to drugs</li>
              <li>
                you are taking a type of medicine known as a monoamine oxidase inhibitor (examples
                include tranylcypromine, phenelzine, isocarboxazid, moclobemide and linezolid), or
                you have taken this type of medicine in the last two weeks
              </li>
              <li>
                you suffer from myasthenia gravis (a condition in which the muscles become weak)
              </li>
              <li>
                you have previously suffered from withdrawal symptoms such as agitation, anxiety,
                shaking or sweating upon stopping taking alcohol
              </li>
            </ul>
            <p className="bold">
              Bunov<sup>&reg;</sup> must not be used to treat symptoms associated with drug
              withdrawal.
            </p>
          </div>
          <div className="right">
            <img className="dropshadow" src={MenField} alt="An elderly couple are out on a walk." />
          </div>
        </div>
      </section>
      <section
        id="how"
        className="section section--white section--half-half how-to-apply pointer pointer--top pointer--left-33"
      >
        <div className="container">
          <div className="left">
            <div className="apply-container">
              <div className="top">
                <div className="box">
                  <img
                    src={Box1}
                    alt="Scissors are cutting the edge of the packaging for a transdermal patch."
                  />
                </div>
                <img src={Arrow1} className="arrow" alt=" " />
                <div className="box">
                  <img
                    src={Box2}
                    alt="One side of the film on the transdermal patch is peeled back."
                  />
                </div>
              </div>
              <div className="centre">
                <img src={Arrow2} className="arrow" alt=" " />
              </div>
              <div className="bottom">
                <div className="box">
                  <img src={Box4} alt="The transdermal patch is stuck onto the upper back." />
                </div>
                <img src={Arrow3} className="arrow" alt=" " />
                <div className="box">
                  <img
                    src={Box3}
                    alt="The transdermal patch is covered with a hand to secure it."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <h2>
              How to apply the
              <br /> transdermal patch<sup>1</sup>
            </h2>
            <p>
              Always use this medicine exactly as your doctor or pharmacist has told you. Check with
              your doctor or pharmacist if you are not sure.
            </p>
            <p>
              Different strengths of Bunov<sup>&reg;</sup> are available. Your doctor will decide
              which strength of Bunov<sup>&reg;</sup> will suit you best.
            </p>
            <p className="bold">
              It is important you apply your Bunov<sup>&reg;</sup> transdermal patches correctly.{" "}
            </p>
            <NavLink
              to="/patient/how-to-apply-bunov"
              data-path="/patient/how-to-apply-bunov"
              className="btn btn--secondary"
            >
              Bunov<sup>&reg;</sup> step-by-step guide
            </NavLink>
          </div>
        </div>
      </section>
      <CTA
        heading="Reported side effects<sup>1</sup>"
        copy="<p>Like all medicines, this medicine can cause side effects, although not everybody will get them. Serious side effects that may be associated with Bunov<sup>&reg;</sup> are similar to those seen with other strong painkillers and include difficulty in breathing and low blood pressure.</p><p>This medicine can cause allergic reactions, although serious allergic reactions are rare. If you get any sudden wheeziness, difficulties in breathing, swelling of the eyelids, face or lips, rash or itching (especially those covering your whole body) you should remove the patch and tell your doctor immediately.</p><p>As with all strong painkillers, there is a risk that you may become addicted or reliant on Bunov<sup>&reg;</sup>.</p>"
        link="/patient/side-effects"
        linkCopy="Potential side effects"
        className="pointer pointer--top pointer--right-50"
        id="side-effects"
      />
      <section id="doctor" className="section section--white section--half-half speak-to-doctor">
        <div className="container">
          <div className="left">
            <h2>Speaking to your doctor</h2>
            <p>
              When managing chronic pain, it is important to ensure your healthcare professional is
              kept up-to-date with how you are finding the treatment. Make sure you keep your doctor
              informed of any side effects you experience, and book in for a visit if you have any
              concerns you would like to discuss.
              <sup>1</sup>
            </p>
            <p>
              If you get any side effects, you should talk to your doctor or pharmacist. This
              includes any possible side effects not listed here. You can also report side effects
              directly via the Yellow Card Scheme Website:{" "}
              <ExternalLink
                link="https://yellowcard.mhra.gov.uk/"
                title="https://yellowcard.mhra.gov.uk/"
              />{" "}
              or search for MHRA Yellow Card in Google Play or Apple App Store. By reporting side
              effects, you can help provide more information on the safety of this medicine.
            </p>
            <NavLink
              to="/patient/useful-resources"
              data-path="/patient/useful-resources"
              className="btn btn--secondary"
            >
              Helpful resources
            </NavLink>
          </div>
          <div className="right">
            <div className="beige-backdrop">
              <h3>
                Talk to your doctor or pharmacist
                <br /> before using Bunov
                <sup>&reg;</sup> if:
              </h3>
              <ul>
                <li>
                  if you are treated with antidepressants. The use of these medicines together with
                  Bunov<sup>&reg;</sup> can lead to serotonin syndrome, a potentially
                  life-threatening condition;
                </li>
                <li>if you suffer from seizures, fits or convulsions;</li>
                <li>
                  if you suffer from a breathing related sleep disorder
                  <br /> (sleep apnoea);
                </li>
                <li>
                  if you have a severe headache or feel sick due to a head injury or increased
                  pressure in your skull (for instance due to brain disease). This is because the
                  patches may make symptoms worse or hide the extent of a head injury;{" "}
                </li>
                <li>if you are feeling light-headed or faint;</li>
                <li>if you have severe liver problems;</li>
                <li>
                  if you or anyone in your family have ever used or been dependent on alcohol,
                  prescription medicines or illegal drugs ("addiction");
                </li>
                <li>if you are a smoker;</li>
                <li>
                  if you have ever had problems with your mood (depression, anxiety or a personality
                  disorder) or have been treated by a psychiatrist for other mental illnesses;
                </li>
                <li>
                  if you have a high temperature, as this may lead to larger quantities of the
                  active ingredient being absorbed into the blood than normal;
                </li>
                <li>if you suffer from constipation.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <References>
        <ol>
          <li>
            Bunov Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
            />
          </li>
          <li>
            Bunov Summary of Product Characteristics (SmPC). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
              title="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
            />
          </li>
        </ol>
      </References>
    </main>
  );
}
