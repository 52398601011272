import React from "react";
import { useSite } from "../../hooks";
import DownloadIcon from "../../img/download-icon.svg";
import LinkIcon from "../../img/link-icon.svg";
import PatientDownloadIcon from "../../img/patient-download-icon.svg";
import PatientLinkIcon from "../../img/patient-link-icon.svg";
import { ExternalLink } from "../../components/";

export default function Resource(props) {
  const { currentSite } = useSite();

  const displayDownloadLinkIcon = () => {
    return currentSite === "hcp" ? (
      <img src={props.type === "download" ? DownloadIcon : LinkIcon} alt=" " />
    ) : (
      <img
        src={props.type === "download" ? PatientDownloadIcon : PatientLinkIcon}
        alt=" "
      />
    );
  };

  const resourceInner = () => {
    return (
      <>
        <div className="icon">
          <img src={props.data.icon.type} alt={props.data.title} />
        </div>
        <div className="data">
          <div className="title">{props.data.title}</div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: props.data.description }}
          ></div>
        </div>
        <div className="action-icon">
          {props.type === "download" ? <span>Download</span> : ""}
          {displayDownloadLinkIcon()}
        </div>
      </>
    );
  };

  const className = `resource ${props.data.trigger}`;

  if (props.type === "download") {
    return (
      <a
        href={props.data.link}
        target="_blank"
        rel="noreferrer"
        className={className}
      >
        {resourceInner()}
      </a>
    );
  } else {
    return (
      <ExternalLink link={props.data.link} className={className}>
        {resourceInner()}
      </ExternalLink>
    );
  }
}
