import React from "react";
import {
  PatientLargeHeading as LargeHeading,
  CTA,
  References,
  ExternalLink,
} from "../../../components";
import WhyHeader from "../../../img/why-header.jpg";
import Skin from "../../../img/skin.png";

export default function Why() {
  return (
    <main>
      <LargeHeading
        image={WhyHeader}
        tagline="why have i been prescribed bunov<sup>&reg;</sup>?"
        alt="An elderly woman is outside and smiling."
        title="Why have I been<br />prescribed Bunov<sup>&reg;</sup>?"
        body="Bunov<sup>&reg;</sup> is used for the treatment of chronic pain. Find out what chronic pain is, and how Bunov<sup>&reg;</sup> acts to prevent it, below."
      />
      <CTA
        heading="What is chronic pain?"
        copy="<p>Pain is a signal from your nervous system that something may be wrong.<sup>1</sup> It is an unpleasant sensory and emotional experience associated with, or resembling that associated with, actual or potential tissue damage.<sup>2</sup> The severity of it can vary widely, from a dull tingle to a sharp burning pain.<sup>1</sup> While acute (or short-term) pain is associated with injury or a current problem, chronic (or long-term) pain is different.<sup>1</sup></p><p>Chronic pain is defined as pain that has been present for more than 12 weeks (beyond the expected time of wound healing). It is a complex phenomenon and can have a considerable impact on quality of life, resulting in significant suffering and disability.<sup>2</sup> There may be a clear cause that started the chronic pain (e.g., injury) or a defined reason for the ongoing pain (e.g., arthritis), however in some cases there is no clear cause.<sup>1</sup></p><p>While chronic pain is not always curable, treatments can help make the pain manageable.<sup>1</sup><br>That’s where Bunov® comes in. </p>"
        styles="white"
      />
      <section className="section section--beige section-how-do section--half-half  pointer pointer--top pointer--left-33  ">
        <div className="container">
          <div className="left">
            <h2>
              How do Bunov<sup>&reg;</sup> chronic pain
              <br /> patches work?
            </h2>
            <p>
              Bunov® is a strong analgesic or pain-relieving patch and has been
              prescribed for you by your doctor to relieve,
              long-lasting (or chronic) moderate pain that requires the use of a strong
              painkiller.<sup>3</sup>
            </p>
            <p>
              Bunov® patches act through the skin. After application, the active
              ingredient, buprenorphine, passes through the skin into the blood.
              Unlike oral analgesia tablets and capsules, each patch can be applied once a week to achieve a strong analgesic effect that will last for 7 days.<sup>3</sup>
            </p>
          </div>
          <div className="right">
            <div className="white-backdrop with-dropshadow">
              <img
                src={Skin}
                alt="A cartoon shows the layers of the skin. Medication from
a transdermal patch reaches the bloodstream."
              />
            </div>
          </div>
        </div>
      </section>
      <References>
        <ol>
          <li>
            Medline Plus. Chronic pain. August 2021. Available at:{" "}
            <ExternalLink
              link="https://medlineplus.gov/chronicpain.html"
              title="https://medlineplus.gov/chronicpain.html"
            />
          </li>
          <li>
            British National Formulary (BNF). Chronic Pain. Available at:{" "}
            <ExternalLink
              link="https://bnf.nice.org.uk/treatment-summary/pain-chronic.html"
              title="https://bnf.nice.org.uk/treatment-summary/pain-chronic.html"
            />
          </li>
          <li>
            Bunov Patient Information Leaflet. Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
            />
          </li>
        </ol>
      </References>
    </main>
  );
}
