import React from 'react';

export default function Heading(props) {

    const children = () => {
        return props.children.map((child) => {
            return child;
        })
    }
    
   return (
       <div className="heading">
           <div className="inner-container">
            <h1>{props.title}</h1>
            {props.children && children()}
           </div>
       </div>
   );
}