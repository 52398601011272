import React from "react";
import { PatientSmallHeading, References, ExternalLink } from "../../../components";
import WashingIcon from "../../../img/washing-icon.svg";
import CalendarIcon from "../../../img/calendar-icon.svg";
import PurpleIcon3 from "../../../img/purple-icon-3.svg";
import PurpleIcon4 from "../../../img/purple-icon-4.svg";
import PurpleIcon5 from "../../../img/purple-icon-5.svg";
import PurpleIcon6 from "../../../img/purple-icon-6.svg";
import PurpleIcon7 from "../../../img/purple-icon-7.svg";
import PurpleIcon8 from "../../../img/purple-icon-8.svg";
import Guide1 from "../../../img/guide1.png";
import Guide2 from "../../../img/guide2.png";
import Guide3 from "../../../img/guide3.png";
import Step1 from "../../../img/step1.png";
import Step2 from "../../../img/step2.png";
import Step3 from "../../../img/step3.png";
import Step4 from "../../../img/step4.png";
import { downloads, links } from "../resources/resource-data";

export default function How() {
  return (
    <main className="page--how">
      <PatientSmallHeading
        tagline="how to apply bunov<sup>&reg;</sup>"
        title="A step-by-step guide<sup>1</sup>"
      />
      <section className="section section--white section--guide section--half-half">
        <div className="container">
          <div className="left">
            <p>
              It is important to read the product package leaflet provided with your
              medicine. This information is not a substitute for the product package
              leaflet. The package leaflet contains additional important information on
              the use of the medicine.
            </p>
            <p>
              When people first start using Bunov&reg; transdermal patches, they often
              experience some nausea and vomiting. This usually passes after the first
              week of treatment. It’s a good idea to book a follow-up appointment with
              your doctor a week or two after you first start using Bunov® patches to
              ensure that you are taking the correct dose and to manage any side effects.
            </p>
            <p>
              During treatment, your doctor may change the patch you use to a smaller or
              larger one, or tell you to use a combination of up to two patches, if
              necessary.{" "}
            </p>
            <p>
              If you feel that the effect of the Bunov&reg; patch is too weak or too
              strong, talk to your doctor or pharmacist.
            </p>
            <p>
              Each time you apply a new Bunov&reg; patch, it needs to be positioned in a
              different location than the last. Leave 3–4 weeks (21–28 days) between
              reusing a site.{" "}
              <a href={downloads[1].link} target="_blank">
                Use our printable patch location tracker
              </a>{" "}
              to ensure you are correctly rotating your patches on each application.
            </p>
          </div>
          <div className="right">
            <div className="beige-backdrop">
              <h3>Important!</h3>
              <ul>
                <li>
                  <span>
                    Do not cut or divide the patch or use a higher dose than recommended
                  </span>
                </li>
                <li>
                  <span>
                    You should not apply more than two patches at the same time, up to a
                    maximum total dose of 40 micrograms/hour
                  </span>
                </li>
                <li>
                  <span>
                    Only increase or decrease your dosing if instructed to do so by your
                    healthcare professional
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--full-width section--white section--steps">
        <div className="container">
          <div className="guide-three">
            <div className="img-wrapper">
              <img src={Guide1} alt="A transdermal patch is attached to the upper arm." />
            </div>
            <div className="divider"></div>
            <div className="img-wrapper">
              <img
                src={Guide2}
                alt="A transdermal patch is attached to the upper chest."
              />
            </div>
            <div className="divider"></div>
            <div className="img-wrapper">
              <img
                src={Guide3}
                alt="A transdermal patch is attached to the upper back."
              />
            </div>
          </div>
        </div>
        <div className="inner-container">
          <p>
            The Bunov<sup>&reg;</sup> patch should be applied to relatively hairless
            non-irritated, intact skin (with no cuts, breaks or scrapes) of the upper
            outer arm, upper chest, upper back or the side of the chest:
          </p>
          <p className="bold">
            Ask for assistance if you cannot apply the patch yourself.
          </p>
          <ul>
            <li>
              Bunov<sup>&reg;</sup> should not be applied where there are large scars
            </li>
            <li>Aim to apply the patch to a relatively hairless skin site</li>
            <li>
              If needed, hair can be trimmed with scissors but should not be shaved prior
              to application
            </li>
            <li>
              If the application site needs cleaning, use lukewarm water only and ensure
              the site is dry prior to application
            </li>
            <li>
              After a hot bath or shower, wait until your skin is completely cool and dry
            </li>
            <li>
              Do not use soap, alcohol, oils, lotions or abrasive devices to clean the
              skin before application – this might prevent your patch from sticking
              properly
            </li>
          </ul>
          <p className="bold">
            Each time you apply Bunov<sup>&reg;</sup>, it needs to be in a different
            location than the last patch
          </p>
          <ul>
            <li>
              You should wait at least 3–4 weeks (21–28 days) before reusing the same skin
              site
            </li>
            <li>
              When you remove your old patch, write down the date and time it was removed
              and how it was disposed. Use our{" "}
              <a target="_blank" href={downloads[1].link}>
                patch location tracker
              </a>{" "}
              to manage your application and removal information
            </li>
            <li>
              Remember to change your patch at the same time of day - it is important that
              you make a note of the time and day you apply the patch
            </li>
          </ul>
          <p>
            Unless your doctor has told you differently, attach one Bunov
            <sup>&reg;</sup> patch (as described in detail below) and change it every 7th
            day, preferably at the same time of day.{" "}
          </p>
          <p>
            Your doctor may wish to adjust the dose after 3–7 days until the correct level
            of pain control has been found. If your doctor has advised you to take other
            painkillers in addition to the patch, strictly follow the doctor’s
            instructions, otherwise you will not fully benefit from treatment with Bunov
            <sup>&reg;</sup>. The patch should be worn for 3 full days before increasing
            the dose, as this is when the maximum effect of a given dose is established.
          </p>
        </div>
        <div className="container">
          <div className="step-container">
            <div className="step">
              <div className="img-wrapper">
                <img
                  src={Step1}
                  alt="Scissors are cutting the edge of the packaging for a transdermal
patch."
                />
              </div>
              <div className="step__copy">
                <h4>Step 1</h4>
                <p>
                  Ensure you have removed any previous patches. Check the patch is the
                  correct dose and check for any signs of damage to the packaging.{" "}
                </p>
                <p>
                  Do not apply the patch if the pouch seal is broken or if the patch is
                  cut, damaged or altered in any way. Cut the sachet along the sealed edge
                  with scissors and remove the patch.
                </p>
              </div>
            </div>

            <div className="step step--reverse">
              <div className="img-wrapper">
                <img
                  src={Step2}
                  alt="One side of the film on the transdermal patch is peeled back."
                />
              </div>
              <div className="step__copy">
                <h4>Step 2</h4>
                <p>
                  The sticky side of the transdermal patch is covered with a transparent
                  protective foil. Carefully peel off <strong>one part</strong> of the
                  foil. Try not to touch the sticky part of the transdermal patch.
                </p>
              </div>
            </div>

            <div className="step">
              <div className="img-wrapper">
                <img
                  src={Step3}
                  alt="The transdermal patch is stuck onto the upper back."
                />
              </div>
              <div className="step__copy">
                <h4>Step 3</h4>
                <p>
                  Stick the open edge of the transdermal patch on the skin and remove the
                  remainder of the foil as you stick it completely to the body.
                </p>
              </div>
            </div>

            <div className="step step--reverse">
              <div className="img-wrapper">
                <img
                  src={Step4}
                  alt="The transdermal patch is covered with a hand to secure it."
                />
              </div>
              <div className="step__copy">
                <h4>Step 4</h4>
                <p>
                  Hold the transdermal patch against the skin with the palm of the hand
                  for about 30 seconds. Make sure the whole surface of the patch is in
                  contact with the skin, especially the edges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--full-width section-beige section--to-remember pointer pointer--top pointer--left-33">
        <div className="container">
          <h2>
            Things to remember<sup>1,2</sup>
          </h2>

          <div className="remember-grid-container">
            <div className="item">
              <div className="purple-icon">
                <img src={WashingIcon} alt=" " />
              </div>
              <p>
                Always wash your hands after applying or handling a patch. Ensure you
                record the date and time of both removal and application of patches.
              </p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={CalendarIcon} alt=" " />
              </div>
              <p>
                Each transdermal patch can be worn for 7 days. You should change the patch
                at the same time of day, exactly 7 days after you apply it.
              </p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={PurpleIcon3} alt=" " />
              </div>
              <p>The patch must not be divided or cut into pieces.</p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={PurpleIcon4} alt=" " />
              </div>
              <p>The patch should not be used if the seal is broken.</p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={PurpleIcon5} alt=" " />
              </div>
              <p>
                If the edges of the patch begin to peel, they can be taped down with a
                suitable skin tape to ensure the full 7-day wear. If the patch falls off,
                do not reapply it. Dispose of it correctly and apply a new patch at a
                different skin site.
              </p>
              <p>
                Make sure you note down the time and site of application of the new patch.
                This can then be worn for the full 7 days.
              </p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={PurpleIcon6} alt=" " />
              </div>
              <p>
                Bathing, showering or swimming should not affect the patch. However,
                patients should avoid exposing the application site to external heat
                sources (e.g., heating pads, electric blankets, hot tubs) as this can
                result in an increased absorption of buprenorphine.
              </p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={PurpleIcon7} alt=" " />
              </div>
              <p>
                When changing the patch, the used patch should be removed, the adhesive
                layer folded inwards on itself, and the patch disposed of safely. Even
                used patches contain some active ingredient that may harm children or
                animals, so make sure your used patches are always kept out of the reach
                and sight of them.
              </p>
            </div>
            <div className="item">
              <div className="purple-icon">
                <img src={PurpleIcon8} alt=" " />
              </div>
              <p>
                Do not throw away any medicines via wastewater or household waste. Ask
                your pharmacist how to throw away medicines you no longer use. These
                measures will help protect the environment.
              </p>
            </div>
          </div>
        </div>
      </section>
      <References>
        <ol>
          <li>
            Bunov Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
            />
          </li>
          <li>
            NHS. Buprenorphine for pain. April 2020. Available at:{" "}
            <ExternalLink
              link="https://www.nhs.uk/medicines/buprenorphine-for-pain/"
              title="https://www.nhs.uk/medicines/buprenorphine-for-pain/"
            />
          </li>
        </ol>
      </References>
    </main>
  );
}
