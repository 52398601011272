import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../App";
import logo from "../../../img/logo.svg";

export default function Header() {
  const { location } = useContext(AppContext);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    positionActiveIndicator();
  }, [location]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      positionActiveIndicator();
    });
  }, []);

  const positionActiveIndicator = () => {
    const activeLink = document.querySelector("nav ul li a.active");
    const indicatorBall = document.querySelector(".indicator__ball");

    if (activeLink === null) {
      indicatorBall.classList.add("indicator__ball--hide");
      return;
    } else {
      const { left } = activeLink.getBoundingClientRect();
      const linkWidth = activeLink.offsetWidth - 10;

      indicatorBall.style.left = `${left + linkWidth / 2}px`;
      indicatorBall.classList.remove("indicator__ball--hide");
    }
  };

  return (
    <>
      <div className="header__notice">
        <div className="container">
          <p>
            THIS SITE IS FOR UK HEALTHCARE PROFESSIONALS ONLY.
            <a href="/">I'm not a HCP</a>
          </p>
          <ul>
            <li>
              <NavLink to="/hcp/pi">Prescribing Information</NavLink>
            </li>
            <li>
              <a href="#ae">Adverse Event Reporting</a>
            </li>
          </ul>
        </div>
      </div>
      <header>
        <div className="header__main">
          <div className="container">
            <div className="header__main__logo">
              <NavLink to="/hcp/">
                <img src={logo} alt="Bunov&reg;" />
              </NavLink>
            </div>
            <div
              className={menuOpened ? "burger-menu open" : "burger-menu"}
              onClick={(e) => setMenuOpened(!menuOpened)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav>
              <ul>
                <li>
                  <NavLink
                    to="/hcp/"
                    data-path="/hcp/"
                    onClick={() => setMenuOpened(false)}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/hcp/initiating-bunov"
                    data-path="/hcp/initiating-bunov"
                    onClick={() => setMenuOpened(false)}
                  >
                    Initiating Bunov<sup>&reg;</sup>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/hcp/resources"
                    data-path="/hcp/resources"
                    onClick={() => setMenuOpened(false)}
                  >
                    Resources
                  </NavLink>
                </li>
                <li className="link-btn">
                  <NavLink
                    to="/hcp/contact-us"
                    data-path="/hcp/contact-us"
                    className="btn btn--primary"
                    onClick={() => setMenuOpened(false)}
                  >
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="indicator">
          <div className="indicator__ball"></div>
        </div>
      </header>
    </>
  );
}
