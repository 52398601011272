import React from "react";
import PillIcon from "../../../img/pill-icon.svg";
import ChecklistIcon from "../../../img/icon-checklist.svg";

export default function SectionSideEffectsPlan() {
  return (
    <section className="section section--grey section--full-width increase-padding">
      <div className="inner-container">
        <div className="tall-block-container">
          <div className="tall-block">
            <div className="icon">
              <img src={PillIcon} alt="" />
            </div>
            <p className="bold">
              Consider the use of short-acting analgesics to help with
              breakthrough pain
              <sup>1</sup>
            </p>
            <p>
              During the first 72 hours after initiating Bunov®, the serum level
              of buprenorphine in your patient’s blood will be fluctuating, and
              they may require additional pain relief to manage symptoms.
            </p>
            <p>
              During this titration period, your patient may take supplemental
              analgesics while the serum levels of buprenorphine normalise to
              manage any breakthrough pain they may experience. However, you may
              want to discuss the level of supplemental medication required to
              provide a defined criteria for monitoring the dosing regimen of
              Bunov® patches going forward.
            </p>
          </div>
          <div className="tall-block">
            <div className="icon">
              <img src={ChecklistIcon} alt="" />
            </div>
            <p className="bold">
              Discuss the side effects of opioid treatment with your patient
              <sup>1</sup>
            </p>
            <p>
              Your patient may have previously been prescribed an opioid, and so
              may have some awareness of the potential harms of opioid
              treatment. However, regardless of the patient’s history, it is
              always important to highlight the risks of opioid dependence and
              the various side effects associated with Bunov®, to ensure they
              have the full complement of information required to make an
              informed decision on their treatment.
            </p>
            <p>
              There is no need for dosage adjustment of Bunov® in patients with
              mild to moderate hepatic impairment. Buprenorphine is metabolised
              in the liver. The intensity and duration of its action may be
              affected in patients with impaired liver function. Therefore, such
              patients should be carefully monitored during treatment with
              Bunov®.
            </p>
            <p>
              Patients with severe hepatic impairment may accumulate
              buprenorphine during Bunov® treatment. Consideration of alternate
              therapy should be considered, and Bunov® should be used with
              caution, if at all, in such patients.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
