import React from "react";
import {
  PatientLargeHeading as LargeHeading,
  CTA,
  References,
  ExternalLink,
} from "../../../components";
import SEHeader from "../../../img/side-effects-header.jpg";
import ManBook from "../../../img/man-book.jpg";
import DrivingIcon from "../../../img/driving-icon.png";
import DrinkIcon from "../../../img/drink-icon.png";
import BTNDownload from "../../../img/btn-download-icon.svg";
import { downloads, links } from "../resources/resource-data";

export default function Why() {
  return (
    <main>
      <LargeHeading
        image={SEHeader}
        tagline="Side effects"
        alt="Two friends are laughing together."
        title="Bunov<sup>&reg;</sup> Patch side effects<br/> and how to manage them "
        body="Like all medicines, this medicine can cause side effects, although not everybody will get them.<br /><br />It is important to read the product package leaflet provided with your medicine. This information is not a substitute for the product package leaflet. The package leaflet contains additional important information on the use of the medicine."
      />
      <section className="section section--white reported-side-effects section--half-half">
        <div className="container">
          <div className="left">
            <h2>Reported side effects</h2>

            <p className="with-marker">
              Very common (may affect more than 1 in 10 people){" "}
            </p>
            <p>
              Headache, dizziness, drowsiness, constipation, feeling or being sick, itchy
              skin, rash, redness, itching, inflammation or swelling of the skin at the
              application site.
            </p>

            <p className="with-marker">Common (may affect up to 1 in 10 people)</p>
            <p>
              Loss of appetite, confusion, depression, anxiety, difficulty in sleeping,
              nervousness, shaking (tremors), shortness of breath, abdominal pain or
              discomfort, diarrhoea, indigestion, dry mouth, sweating, rash, skin
              eruptions, tiredness, a feeling of unusual weakness, muscle weakness,
              swelling of hands, ankles or feet.
            </p>
          </div>
          <div className="right">
            <img
              className="dropshadow"
              src={ManBook}
              alt="A middle aged man is outside and is reading
a book."
            />
          </div>
        </div>
      </section>
      <section className="section section--beige section--half-half bunov-and-driving pointer pointer--top pointer--left-33">
        <div className="container">
          <div className="left">
            <h3>
              <img src={DrivingIcon} alt="" />{" "}
              <span>
                Bunov<sup>&reg;</sup> and driving <br />
                or using machines
              </span>
            </h3>
            <p>
              Bunov<sup>&reg;</sup> may affect your reactions to such an extent that you
              may not react adequately or quickly enough in the event of unexpected or
              sudden occurrences. This applies particularly:
            </p>
            <ul>
              <li>at the beginning of treatment</li>
              <li>if you are taking medicines to treat anxiety or help you sleep</li>
              <li>if your dose is increased</li>
            </ul>
            <p>
              Talk to your doctor or pharmacist if you are not sure whether it is safe for
              you to drive while taking this medicine.
            </p>
            <p>
              If you are affected (e.g., feel dizzy, drowsy or have blurred vision), you
              should not drive or operate machinery whilst using Bunov<sup>&reg;</sup>, or
              for 24 hours after removing the patch.
            </p>
          </div>
          <div className="right">
            <div className="white-backdrop">
              <p>
                Bunov® can affect your ability to drive as it may make you sleepy or
                dizzy.
              </p>
              <ul>
                <li>
                  Do not drive while taking this medicine until you know how it affects
                  you
                </li>
                <li>
                  It is an offence to drive if this medicine affects your ability to drive
                </li>
              </ul>
              <p style={{ margin: "1rem 0" }}>
                However, you would not be committing an offence if:
              </p>
              <ul>
                <li>
                  The medicine has been prescribed to treat a medical or dental problem
                  and
                </li>
                <li>
                  You have taken it according to the instructions given by the prescriber
                  or in the information provided with the medicine and
                </li>
                <li>It was not affecting your ability to drive safely</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <CTA
        icon={DrinkIcon}
        heading="Bunov<sup>&reg;</sup> and alcohol"
        copy="<p>Alcohol may make some of the side effects worse and you may feel unwell if you drink alcohol whilst<br />wearing Bunov<sup>&reg;</sup>. Drinking alcohol whilst using Bunov® may also affect your reaction time.</p>"
        link={downloads[2].link}
        styles="white"
        linkCopy="Side effect information"
        className=""
        buttonIcon={BTNDownload}
      />
      <References showDivider={true}>
        <ol>
          <li>
            Bunov Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
            />
          </li>
        </ol>
      </References>
    </main>
  );
}
