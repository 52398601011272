export default function useSite() {
  const path = new URL(window.location).pathname;
  const pieces = path.split("/");

  var filtered = pieces.filter(function (el) {
    return el !== "";
  });

  if (filtered[0] === "hcp") {
    return {
      currentSite: "hcp",
    };
  } else if (filtered[0] === "patient") {
    return {
      currentSite: "patient",
    };
  } else if (filtered[0] === "par") {
    return {
      currentSite: "par",
    };
  } else if (filtered[0] === "redirect") {
    return {
      currentSite: "redirect",
    };
  } else {
    return {
      currentSite: "root",
    };
  }
}
