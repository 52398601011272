import React, { useEffect, useRef } from "react";
import ManFieldJPG from "../../../img/man-field2.jpg";
import GreenCircleJPG from "../../../img/circles-review.png";
import GreenCircleMobile from "../../../img/mobile-circles.png";
import useSize from "../../../hooks/useSize";
import { greenBackground } from "../../../utilities/greenBackground";

export default function SectionReview() {
  const imageRef = useRef();
  const backgroundRef = useRef();
  const size = useSize(imageRef);

  useEffect(() => {
    if (typeof size.width !== "undefined") greenBackground(backgroundRef, size, "left");
  }, [size]);

  return (
    <section className="section section--white section--half-half hcp-review">
      <div className="green-background green-background--left" ref={backgroundRef}>
        <img src={GreenCircleJPG} className="hide-mobile" alt=" " />
        <img src={GreenCircleMobile} className="mobile-circles hide-desktop" alt=" " />
      </div>
      <div className="inner-container">
        <div className="left align-start">
          <img
            src={ManFieldJPG}
            alt="An elderly gentleman is wearing a cap and waterproof coat. He is looking into an open field."
            className="couple-image man"
            ref={imageRef}
          />
        </div>
        <div className="right">
          <h2>Review the treatment with your patient within 4 weeks of Bunov® initiation</h2>
          <p>
            Patients treated with Bunov® should be carefully and regularly monitored to assess the
            optimum dose and duration of treatment.
            <sup>1</sup> Where practical, review of long-term opioid therapy should be carried out
            by the original prescriber until an agreed long-term regimen has been established.
            <sup>3</sup> It is recommended that the patient should be reviewed within 4 weeks of
            initiation of treatment and lines of communication should be open during the initial
            titration period.<sup>3</sup>
          </p>
        </div>
      </div>
    </section>
  );
}
